import { Card, CardContent, Divider, Grid, Tooltip, Typography } from '@mui/material';
import { Theme } from '@mui/material/styles';
import clsx from 'clsx';
import React, { ReactNode } from 'react';
import { compose } from 'recompose';
import Gauge, { GaugeInstance } from 'svg-gauge';

import { WithStyles, withStyles } from '@/hocs/with-styles';

import OEETargetValue from './oee-target-value';

const styles = (theme: Theme) => ({
  card: {
    width: '100%',
    minHeight: '150px',
    '@media print': {
      maxHeight: '250px',
    },
  },
  cardHover: {
    transition: 'box-shadow .25s',
    '&:hover': {
      cursor: 'pointer',
      boxShadow:
        '0px 3px 5px -1px rgba(0, 0, 0, 0.2), 0px 5px 8px 0px rgba(0, 0, 0, 0.14), 0px 1px 14px 0px rgba(0, 0, 0, 0.12)',
    },
  },
  headingContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    height: '30px',
  },
  title: {
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    position: 'absolute',
  },
  valueContainer: {
    display: 'flex',
  },
  gaugeWrapper: {
    zIndex: 1,
    display: 'flex',
    position: 'relative',
    justifyContent: 'center',
    alignItems: 'center',
  },
  customTargetWrapper: {
    zIndex: 2,
    height: '100%',
    display: 'flex',
    justifyContent: 'center',
    position: 'absolute',
    left: 0,
    right: 0,
    top: 0,
    bottom: 0,
    overflow: 'inherit',
  },
  gaugeContainer: {
    width: '100%',
    maxWidth: '300px',
    display: 'block',
  },
  gaugeContainerSmall: {
    width: '100%',
    maxWidth: '45px',
    maxHeight: '70px',
    display: 'block',
  },
  gaugeValue: {
    strokeWidth: '6',
  },
  gaugeDial: {
    stroke: '#efefef',
    strokeWidth: '6',
  },
  gaugeText: {
    fill: theme.palette.text.primary,
    fontFamily: 'sans-serif',
    fontWeight: 'bold',
    fontSize: '0.9em',
  },
  gaugeTextSmall: {
    fill: theme.palette.text.primary,
    fontFamily: 'sans-serif',
    fontWeight: 'bold',
    fontSize: '1.8em',
  },
});

interface Properties {
  value: number;
  targetValue?: number | null;
  title: NonNullable<ReactNode>;
  onClick?: () => void;
  gaugeColor: string;
  size?: 'small';
  smallDown?: boolean;
  additionalInfo?: string;
}
export type OeeKPIDefaultOptions = {
  animDuration: number;
  showValue: boolean;
  max: number;
  min: number;
  color: () => string;
  gaugeClass: string;
  dialClass: string;
  valueDialClass: string;
  valueClass: string;
  gaugeTargetValueClass: string;
  label: (value: unknown) => string;
};

class OEEKPICard extends React.Component<Properties & WithStyles<typeof styles>, {}> {
  public gauge: GaugeInstance | null = null;
  public gaugeEl: HTMLDivElement | null = null;

  defaultOptions: OeeKPIDefaultOptions = {
    animDuration: 1,
    showValue: true,
    max: 100,
    min: 0,
    color: () => this.props.gaugeColor,
    gaugeClass: this.props.classes.gaugeContainer,
    dialClass: this.props.classes.gaugeDial,
    valueDialClass: this.props.classes.gaugeValue,
    valueClass: this.props.classes.gaugeText,
    gaugeTargetValueClass: 'gaugeTargetValue',
    label: (value: unknown) => {
      if (typeof value !== 'number') {
        return '&infin; %';
      }

      return `${value.toFixed(1)} %`;
    },
  };

  public componentDidMount() {
    this.renderGauge(this.props);
  }

  public shouldComponentUpdate(nextProps: Properties & WithStyles<typeof styles>, _: {}) {
    const { value } = this.props;

    if (value !== nextProps.value) {
      this.renderGauge(nextProps);
      return true;
    }
    return true;
  }

  public renderGauge(props: Properties & WithStyles<typeof styles>) {
    const gaugeOptions = Object.assign({}, this.defaultOptions, props);
    if (props.size === 'small') {
      gaugeOptions.valueClass = props.classes.gaugeTextSmall;
      gaugeOptions.label = (value: unknown) => {
        if (typeof value !== 'number') {
          return '&infin;';
        }

        return `${value.toFixed(1)}`;
      };
    }

    if (this.gaugeEl) {
      this.gauge = this.gauge || Gauge(this.gaugeEl, gaugeOptions);
      this.gauge.setValueAnimated(props.value, gaugeOptions.animDuration);
    }
  }
  render() {
    const { size } = this.props;
    return (
      <Grid item xs={size === 'small' ? 3 : 12} sm={3} md={3} lg={3}>
        {size === 'small' ? this.renderOeeSmall() : this.renderOeeWithCard()}
      </Grid>
    );
  }

  public renderOeeWithCard() {
    const { classes, title, onClick, targetValue, additionalInfo } = this.props;

    return (
      <Card
        onClick={onClick ? onClick : undefined}
        className={clsx(classes.card, onClick && classes.cardHover)}
        elevation={1}
      >
        <CardContent>
          <Tooltip placement="top" title={additionalInfo ?? ''}>
            <div className={classes.headingContainer}>
              <Typography align="center" variant="body1" className={classes.title}>
                {title}
              </Typography>
            </div>
          </Tooltip>

          <Divider sx={{ '@media print': { display: 'none' } }} />
          <div className={classes.gaugeWrapper}>
            <div className={classes.valueContainer}>
              <div className={classes.gaugeContainer} ref={(el) => (this.gaugeEl = el)} />
            </div>
            {targetValue ? (
              <div className={classes.customTargetWrapper}>
                <OEETargetValue targetValue={targetValue} />
              </div>
            ) : null}
          </div>
        </CardContent>
      </Card>
    );
  }

  public renderOeeSmall() {
    const { classes, title } = this.props;
    return (
      <div className={classes.valueContainer}>
        <div className={classes.gaugeContainerSmall} ref={(el) => (this.gaugeEl = el)}>
          <Typography align="center">{title}</Typography>
        </div>
      </div>
    );
  }
}

const enhance = compose<unknown, Properties>(withStyles(styles));
export default enhance(OEEKPICard as React.ComponentType<unknown>);
