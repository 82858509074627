import { Grid, Theme } from '@mui/material';
import * as Schema from 'generated/graphql/schema';
import React from 'react';
import { WithTranslation, withTranslation } from 'react-i18next';
import { compose } from 'recompose';

import { WithStyles, withStyles } from '@/hocs/with-styles';
import OEEKPICard from '@/views/tabs/oee/oee-kpi-card';

const styles = (theme: Theme) => ({
  card: {
    width: '100%',
    minHeight: '70px',
  },
  loadingCardContent: {
    height: '200px',
  },
  flexContainer: {
    display: 'flex',
  },
  includeScrapButton: {
    marginLeft: theme.spacing(2),
  },
});

interface Properties {
  oee: Partial<Schema.Oee> | null;
}

class Oee extends React.Component<Properties & WithTranslation & WithStyles<typeof styles>> {
  constructor(props: Properties & WithTranslation & WithStyles<typeof styles>) {
    super(props);
  }

  public render() {
    const { theme } = this.props;
    const { oee1 = 0, oee2 = 0, oee3 = 0, tcu = 0 } = this.props.oee || {};

    return (
      <Grid container spacing={1} alignItems="stretch" justifyContent="flex-start">
        <OEEKPICard gaugeColor={theme.blackbird.stops.NO_ACT.color.main} title={'TCU'} value={tcu} size="small" />
        <OEEKPICard
          gaugeColor={theme.blackbird.stops.NON_PROD.color.main}
          title={
            <>
              OEE
              <sub>3</sub>
            </>
          }
          value={oee3}
          size="small"
        />
        <OEEKPICard
          gaugeColor={theme.blackbird.stops.BATCH_NON_PROD.color.main}
          title={
            <>
              OEE
              <sub>2</sub>
            </>
          }
          value={oee2}
          size="small"
        />
        <OEEKPICard
          gaugeColor={theme.blackbird.stops.DOWNTIME.color.main}
          title={
            <>
              OEE
              <sub>1</sub>
            </>
          }
          value={oee1}
          size="small"
        />
      </Grid>
    );
  }
}
const enhance = compose<unknown, Properties>(withTranslation(['shared']), withStyles(styles, { withTheme: true }));
export default enhance(Oee as React.ComponentType<unknown>);
