import { useLazyQuery } from '@apollo/client';
import {
  DoNotDisturb,
  SignalCellular0Bar,
  SignalCellular4Bar,
  SignalWifi0Bar,
  SignalWifi4Bar,
} from '@mui/icons-material';
import { Avatar, Tooltip } from '@mui/material';
import clsx from 'clsx';
import * as Schema from 'generated/graphql/schema';
import React, { useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { makeStyles } from 'tss-react/mui';

import { deviceNetworkStatus } from '@/graphql/queries';

const useStyles = makeStyles()((theme) => ({
  avatarWhite: {
    backgroundColor: '#fff',
  },
  iconBlack: {
    color: theme.palette.primary.main,
  },
}));

type DeviceSignalProps = {
  isUnclaimed?: boolean;
  online: boolean;
} & ({ type: Schema.NetworkConnectionType; wifiEnabled?: undefined } | { type?: undefined; wifiEnabled: boolean });

/** A component to display the connectivity of a device */
export const DeviceSignalAvatar: React.FC<DeviceSignalProps> = ({ isUnclaimed, online, wifiEnabled, type }) => {
  const [t, i18n] = useTranslation();
  const { classes } = useStyles();

  const { WIFI, CELLULAR } = Schema.NetworkConnectionType;

  let actualType = type ?? (wifiEnabled ? WIFI : CELLULAR);

  const helperText = useMemo(() => {
    if (isUnclaimed) {
      return t('shared:deviceArchived', { defaultValue: 'Device archived' });
    }
    if (online === false) {
      return t('device:offline', { defaultValue: 'This device is offline' });
    }
    if (actualType === WIFI) {
      return t('device:wifiConnected', { defaultValue: 'WiFi connected' });
    } else {
      return t('shared:cellularConnected', { defaultValue: 'Cellular connected' });
    }
  }, [i18n.language, isUnclaimed, online, actualType]);

  let icon: JSX.Element;
  if (isUnclaimed) {
    icon = <DoNotDisturb />;
  } else if (actualType === WIFI) {
    if (online) {
      icon = <SignalWifi4Bar />;
    } else {
      icon = <SignalWifi0Bar />;
    }
  } else {
    if (online) {
      icon = <SignalCellular4Bar />;
    } else {
      icon = <SignalCellular0Bar />;
    }
  }
  return (
    <Tooltip
      title={helperText}
      children={<Avatar className={clsx(classes.avatarWhite, classes.iconBlack, 'hideInPercy')}>{icon}</Avatar>}
    />
  );
};

interface SignalAvatarProps {
  uuid: string | null;
  online: boolean;
  isUnclaimed?: boolean;
}

/** A component displaying the signal for a sensor (does an interal query for now until fallback is implemented) */
const SignalAvatar: React.FC<SignalAvatarProps> = ({ isUnclaimed, uuid, online }) => {
  const [update, { data, loading }] = useLazyQuery<
    Schema.DeviceNetworkStatusQuery,
    Schema.DeviceNetworkStatusQueryVariables
  >(deviceNetworkStatus);
  const type = data?.device?.network?.connection ?? Schema.NetworkConnectionType.WIFI;

  useEffect(() => {
    if (uuid) {
      update({ variables: { uuid } });
    }
  }, [uuid]);

  return <DeviceSignalAvatar {...{ online, isUnclaimed, type }} />;
};
export default SignalAvatar;
