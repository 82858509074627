import Link from '@blackbird/ui-base/link';
import { MoreVert as MoreVertIcon, OpenInBrowser, SettingsSharp } from '@mui/icons-material';
import { Divider, IconButton, ListItemIcon, Menu, MenuItem, Typography } from '@mui/material';
import * as Schema from 'generated/graphql/schema';
import React, { FC, useCallback, useMemo, useState } from 'react';
import { makeStyles } from 'tss-react/mui';

import PinItem from '@/components/pin-item';
import * as Constants from '@/constants';
import { useIAM, useTranslation } from '@/hooks';
import * as Types from '@/types';

const useStyles = makeStyles()((theme) => ({
  root: {
    flex: '0 0 auto',
    '@media print': {
      display: 'none',
    },
  },
  menuItemStyles: {
    padding: theme.spacing(),
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
  },
  menuItemIcon: {
    minWidth: theme.spacing(4),
  },
}));

interface Properties {
  lineId: string;
  mainSensor?: Types.DeepPartial<Schema.LineOverviewWithKpiQuery['line']['mainSensor']>;
  openDialog: () => void;
}

const LineOverviewCardMenu: FC<Properties> = ({ lineId, openDialog, mainSensor }) => {
  const { classes } = useStyles();

  const { hasFeatureInAnyGroup } = useIAM();
  const [t] = useTranslation(['shared', 'line']);
  const buttons = useMemo(
    () => [
      {
        label: t(['shared:registerStops'], { defaultValue: 'Register Stops' }),
        uri: 'register-stops',
      },
      {
        label: t(['shared:batches'], { defaultValue: 'Batches' }).titleCase,
        uri: 'batches',
      },
      {
        label: t(['shared:analytics'], { defaultValue: 'Analytics' }),
        uri: 'analytics',
      },
      {
        label: t(['shared:oee'], { defaultValue: 'OEE' }),
        uri: 'oee',
      },
      {
        label: t(['shared:trends'], { defaultValue: 'Trends' }),
        uri: 'trends',
      },
    ],
    [t],
  );

  const [menuAnchor, setMenuAnchor] = useState<HTMLElement | null>(null);
  const clearMenuAnchor = useCallback(() => setMenuAnchor(null), []);

  const handleMenuClick = useCallback((event: React.MouseEvent<HTMLElement, MouseEvent>) => {
    event.preventDefault();
    event.stopPropagation();
    setMenuAnchor(event.currentTarget);
  }, []);

  const handleMenuClose = useCallback((event: React.MouseEvent<HTMLElement, MouseEvent>) => {
    event.preventDefault();
    event.stopPropagation();
    clearMenuAnchor();
  }, []);

  const onLineSettingsClick = useCallback((event: React.MouseEvent<HTMLElement, MouseEvent>) => {
    event.preventDefault();
    event.stopPropagation();
    clearMenuAnchor();
    openDialog();
  }, []);

  const onMenuItemClick = useCallback(() => setMenuAnchor(null), []);

  const mainSensorUnitLabel = mainSensor?.config?.sensorUnit?.label ?? '';
  const openMenu = Boolean(menuAnchor);

  const canEditLines = hasFeatureInAnyGroup(['Lines.Line'], 'M');

  return (
    <div className={classes.root}>
      <IconButton
        aria-label="More"
        aria-owns={openMenu ? 'long-menu' : undefined}
        aria-haspopup="true"
        onClick={handleMenuClick}
        size="large"
      >
        <MoreVertIcon />
      </IconButton>
      <Menu id="long-menu" anchorEl={menuAnchor} open={openMenu} onClose={handleMenuClose} PaperProps={{ style: {} }}>
        {buttons.map((btn) => (
          <Link key={btn.uri} href={{ pathname: '/line', query: { lineId, tab: btn.uri } }}>
            <MenuItem
              key={`${btn.uri}-Menu`}
              selected={false}
              onClick={onMenuItemClick}
              className={classes.menuItemStyles}
            >
              <Typography variant="inherit" noWrap>
                {btn.label}
              </Typography>
            </MenuItem>
          </Link>
        ))}
        <Divider variant="middle" />
        {!canEditLines ? null : (
          <MenuItem
            key={'lineSettingsMenu'}
            selected={false}
            onClick={onLineSettingsClick}
            className={classes.menuItemStyles}
          >
            <ListItemIcon className={classes.menuItemIcon}>
              <SettingsSharp />
            </ListItemIcon>
            <Typography variant="inherit" noWrap>
              {t(['line:lineSettings'], { defaultValue: 'Line settings' })}
            </Typography>
          </MenuItem>
        )}
        <Link key={'lineSchedule'} href={{ pathname: '/line', query: { lineId, tab: 'schedule' } }}>
          <MenuItem
            key={'lineScheduleMenu'}
            selected={false}
            onClick={onMenuItemClick}
            className={classes.menuItemStyles}
          >
            <ListItemIcon className={classes.menuItemIcon}>
              <Constants.SCHEDULE_ICON />
            </ListItemIcon>
            <Typography variant="inherit" noWrap>
              {t(['shared:schedule'], { defaultValue: 'Schedule' })}
            </Typography>
          </MenuItem>
        </Link>
        <Link href={{ pathname: '/dashboard/batch', query: { lineId, layout: 'compact' } }}>
          <MenuItem
            key={'batchDashboardMenu'}
            selected={false}
            onClick={onMenuItemClick}
            className={classes.menuItemStyles}
          >
            <ListItemIcon className={classes.menuItemIcon}>
              <OpenInBrowser />
            </ListItemIcon>
            <Typography variant="inherit" noWrap>
              {t(['shared:batchDashboard'], { defaultValue: 'Batch Dashboard' })}
            </Typography>
          </MenuItem>
        </Link>
        <Link
          key={'shiftDashboard'}
          href={{ pathname: '/dashboard/shift', query: { lineId, layout: 'compact', mainSensorUnitLabel } }}
        >
          <MenuItem
            key={'shiftDashboardMenu'}
            selected={false}
            onClick={onMenuItemClick}
            className={classes.menuItemStyles}
          >
            <ListItemIcon className={classes.menuItemIcon}>
              <OpenInBrowser />
            </ListItemIcon>
            <Typography variant="inherit" noWrap>
              {t(['shared:shiftDashboard'], { defaultValue: 'Shift Dashboard' })}
            </Typography>
          </MenuItem>
        </Link>
        <Divider variant="middle" />
        <PinItem identifier={lineId} storage={'LINES'} />
      </Menu>
    </div>
  );
};

export default LineOverviewCardMenu;
